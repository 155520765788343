<template>
  <div>
    <page-header-sub :sub-title="$t(header.solutionName)"
                     :title="$t(header.title)"
                     :description="$t(header.description)"
                     :image-aspect-ratio="1160/1101"
                     :image="header.image"/>

    <image-section :section-title="$t(coreTechSectTitle)"
                   :contents-title="$t(coreTech.title)"
                   :description="$t(coreTech.description)"
                   :image="coreTech.image"
                   :image-aspect-ratio="2360/1101"
                   mode="row"/>

    <image-section
        :section-title="idx === 0 ? $t(stepSectTitle) : null"
        :contents-sub-title="`STEP ${idx+1}`"
        :contents-title="$t(step.title)"
        contents-title-transform="capitalize"
        :description="$t(step.description)"
        :image="step.image"
        :image-aspect-ratio="1360/1101"
        reverse
        v-for="(step, idx) in steps" :key="idx"/>

    <card-section :title="featureSectTitle" :cards="features" card-cols-md="4"/>
  </div>
</template>

<script>
import PageHeaderSub from '@/layouts/ServiceIntro/Sections/PageHeaderSub';
import ImageSection from '@/layouts/ServiceIntro/Sections/ImageSection';
import CardSection from '@/layouts/ServiceIntro/Sections/CardSection';

export default {
  name: 'Solution-AudienceView',
  components: {
    PageHeaderSub,
    ImageSection,
    CardSection,
  },
  data: () => ({
    header: {
      solutionName: 'Audience Solution',
      title: 'audienceTitle',
      description: 'audienceDesc',
      image: require('@/assets/images/solution_audience@2x.png'),
    },

    coreTechSectTitle: 'coreTech',
    coreTech: {
      title: 'audienceTechShortDesc1',
      description: 'audienceTechDesc1',
      image: require('@/assets/images/solution/audience/coreTech@2x.png'),
    },

    stepSectTitle: 'audienceStepSectionTitle',
    steps: [
      {
        title: 'audienceStepShortDesc1',
        description: 'audienceStepDesc1',
        image: require(
            '@/assets/images/solution/audience/step_01_personalized target prediction@2x.png'),
      },
      {
        title: 'audienceStepShortDesc2',
        description: 'audienceStepDesc2',
        image: require('@/assets/images/solution/audience/step_02_target user analysis@2x.png'),
      },
      {
        title: 'audienceStepShortDesc3',
        description: 'audienceStepDesc3',
        image: require('@/assets/images/solution/audience/step_03_setting reward@2x.png'),
      },
      {
        title: 'audienceStepShortDesc4',
        description: 'audienceStepDesc4',
        image: require(
            '@/assets/images/solution/audience/step_04_confirm through blockchain@2x.png'),
      },
    ],

    featureSectTitle: 'Features',
    features: [
      {
        title: 'audienceFeatureShortDesc1',
        icon: 'fas fa fa-bullseye-arrow',
        description: 'audienceFeatureDesc1',
      },
      {
        title: 'audienceFeatureShortDesc2',
        icon: 'fas fa fa-hand-holding-usd',
        description: 'audienceFeatureDesc2',
      },
      {
        title: 'audienceFeatureShortDesc3',
        icon: 'fas fa fa-cubes',
        description: 'audienceFeatureDesc3',
      },
    ],
  }),
};
</script>
