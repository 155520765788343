<template>
  <div>
    <page-header-sub :sub-title="$t(header.solutionName)"
                     :title="$t(header.title)"
                     :description="$t(header.description)"
                     :image-aspect-ratio="1160/1101"
                     :image="header.image"/>

    <image-section :section-title="$t(coreTechSectTitle)"
                   :contents-title="$t(coreTech.title)"
                   :description="$t(coreTech.description)"
                   :image="coreTech.image"
                   :image-aspect-ratio="2360/1000"
                   mode="row"/>

    <div>
      <image-section :section-title="$t(functionSectTitle)"
                     :contents-title="$t(functionSectSubTitle)"
                     :description="$t(functionSectDescription)"
                     :image="functionSectImage"
                     :image-aspect-ratio="2360/1001"
                     mode="row"/>

      <image-section :contents-sub-title="`Function ${idx+1}`"
                     :contents-title="$t(func.title)"
                     contents-title-transform="capitalize"
                     :description="$t(func.description)"
                     :image="func.image"
                     :image-aspect-ratio="1360/1001"
                     reverse
                     v-for="(func, idx) in functions" :key="idx"/>
    </div>

    <card-section :title="featureSectTitle" :cards="features" card-cols-md="4"/>
  </div>
</template>

<script>
import PageHeaderSub from '@/layouts/ServiceIntro/Sections/PageHeaderSub';
import ImageSection from '@/layouts/ServiceIntro/Sections/ImageSection';
import CardSection from '@/layouts/ServiceIntro/Sections/CardSection';

export default {
  name: 'Solution-RecommendationView',
  components: {
    PageHeaderSub,
    ImageSection,
    CardSection,
  },
  data: () => ({
    header: {
      solutionName: 'Recommendation Solution',
      title: 'recomTitle',
      description: 'recomDesc',
      image: require('@/assets/images/solution_recommendation@2x.png'),
    },

    coreTechSectTitle: 'coreTech',
    coreTech: {
      title: 'recomTechShortDesc1',
      description: 'recomTechDesc1',
      image: require('@/assets/images/solution/recommendation/core technology.svg'),
    },

    functionSectTitle: 'recomFunctionSectionTitle',
    functionSectSubTitle: 'recomFunctionSectionSubTitle',
    functionSectDescription: 'recomFunctionSectionDesc',
    functionSectImage: require(
        '@/assets/images/solution/recommendation/provide service according to situation.svg'),
    functions: [
      {
        title: 'recomFunctionShortDesc1',
        description: 'recomFunctionDesc1',
        image: require(
            '@/assets/images/solution/recommendation/function_01_hyper personalized recommendation@2x.png'),
      },
      {
        title: 'recomFunctionShortDesc2',
        description: 'recomFunctionDesc2',
        image: require(
            '@/assets/images/solution/recommendation/function_02_CF based recommendation@2x.png'),
      },
      {
        title: 'recomFunctionShortDesc3',
        description: 'recomFunctionDesc3',
        image: require(
            '@/assets/images/solution/recommendation/function_03_popularity based recommendation@2x.png'),
      },
      {
        title: 'recomFunctionShortDesc4',
        description: 'recomFunctionDesc4',
        image: require(
            '@/assets/images/solution/recommendation/function_04_segment based recommendation@2x.png'),
      },
    ],

    featureSectTitle: 'Features',
    features: [
      {
        title: 'recomFeatureShortDesc1',
        icon: 'fas fa fa-database',
        description: 'recomFeatureDesc1',
      },
      {
        title: 'recomFeatureShortDesc2',
        icon: 'fas fa fa-star',
        description: 'recomFeatureDesc2',
      },
      {
        title: 'recomFeatureShortDesc3',
        icon: 'fas fa fa-user',
        description: 'recomFeatureDesc3',
      },
    ],
  }),
};
</script>
