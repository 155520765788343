<template>
  <div>
    <page-header-sub :sub-title="$t(header.solutionName)"
                     :title="$t(header.title)"
                     :description="$t(header.description)"
                     :image-aspect-ratio="1160/1101"
                     :image="header.image"/>

    <image-section
        :contents-sub-title="`Insight ${idx+1}`"
        :contents-title="$t(func.title)"
        :description="$t(func.description)"
        :image="func.image"
        :image-aspect-ratio="1360/1001"
        reverse
        v-for="(func, idx) in functions" :key="idx"/>
  </div>
</template>

<script>
import PageHeaderSub from '@/layouts/ServiceIntro/Sections/PageHeaderSub';
import ImageSection from '@/layouts/ServiceIntro/Sections/ImageSection';
import CardSection from '@/layouts/ServiceIntro/Sections/CardSection';

export default {
  name: 'Solution-InsightView',
  components: {
    PageHeaderSub,
    ImageSection,
    CardSection,
  },
  data: () => ({
    header: {
      solutionName: 'Insight Solution',
      title: 'insightTitle',
      description: 'insightDesc',
      image: require('@/assets/images/solution_insight@2x.png'),
    },

    functions: [
      {
        title: 'insightFunctionShortDesc1',
        description: 'insightFunctionDesc1',
        image: require('@/assets/images/solution/insight/function_01_data insight@2x.png'),
      },
      {
        title: 'insightFunctionShortDesc2',
        description: 'insightFunctionDesc2',
        image: require(
            '@/assets/images/solution/insight/function_02_recommendation insight@2x.png'),
      },
      {
        title: 'insightFunctionShortDesc3',
        description: 'insightFunctionDesc3',
        image: require('@/assets/images/solution/insight/function_03_search insight@2x.png'),
      },
      {
        title: 'insightFunctionShortDesc4',
        description: 'insightFunctionDesc4',
        image: require('@/assets/images/solution/insight/function_04_audience insight@2x.png'),
      },
    ],
  }),
};
</script>
