<template>
  <div>
    <page-header-sub :sub-title="$t(header.solutionName)"
                     :title="$t(header.title)"
                     :description="$t(header.description)"
                     :image-aspect-ratio="1160/1101"
                     :image="header.image"/>

    <image-section :section-title="$t(coreTechSectTitle)"
                   :contents-title="$t(coreTech.title)"
                   :description="$t(coreTech.description)"
                   :image="coreTech.image"
                   :image-aspect-ratio="2360/1101"
                   mode="row"/>

    <image-section
        :section-title="idx === 0 ? $t(functionSectTitle) : null"
        :contents-sub-title="`Function ${idx+1}`"
        :contents-title="$t(func.title)"
        contents-title-transform="capitalize"
        :description="$t(func.description)"
        :image="func.image"
        reverse
        v-for="(func, idx) in functions" :key="idx"/>

    <card-section :title="featureSectTitle" :cards="features" card-cols-md="4"/>
  </div>
</template>

<script>
import PageHeaderSub from '@/layouts/ServiceIntro/Sections/PageHeaderSub';
import ImageSection from '@/layouts/ServiceIntro/Sections/ImageSection';
import CardSection from '@/layouts/ServiceIntro/Sections/CardSection';

export default {
  name: 'Solution-SearchView',
  components: {
    PageHeaderSub,
    ImageSection,
    CardSection,
  },
  data: () => ({
    header: {
      solutionName: 'Search Solution',
      title: 'searchTitle',
      description: 'searchDesc',
      image: require('@/assets/images/solution_search@2x.png'),
    },

    coreTechSectTitle: 'coreTech',
    coreTech: {
      title: 'searchTechShortDesc1',
      description: 'searchTechDesc1',
      image: require('@/assets/images/solution/search/coreTech@2x.png'),
    },

    functionSectTitle: 'searchFunctionSectionTitle',
    functions: [
      {
        title: 'searchFunctionShortDesc1',
        description: 'searchFunctionDesc1',
        image: require('@/assets/images/solution/search/function_01_persolnalized search@2x.png'),
      },
      {
        title: 'searchFunctionShortDesc2',
        description: 'searchFunctionDesc2',
        image: require('@/assets/images/solution/search/function_02_facet search@2x.png'),
      },
      {
        title: 'searchFunctionShortDesc3',
        description: 'searchFunctionDesc3',
        image: require(
            '@/assets/images/solution/search/function_03_customized setting for search condition@2x.png'),
      },
      {
        title: 'searchFunctionShortDesc4',
        description: 'searchFunctionDesc4',
        image: require('@/assets/images/solution/search/function_04_reflect magic keyword@2x.png'),
      },
    ],

    featureSectTitle: 'Features',
    features: [
      {
        title: 'searchFeatureShortDesc1',
        icon: 'fas fa fa-sliders-v-square',
        description: 'searchFeatureDesc1',
      },
      {
        title: 'searchFeatureShortDesc2',
        icon: 'fas fa fa-star',
        description: 'searchFeatureDesc2',
      },
      {
        title: 'searchFeatureShortDesc3',
        icon: 'fas fa fa-tachometer-fast',
        description: 'searchFeatureDesc3',
      },
    ],
  }),
};
</script>
